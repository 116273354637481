import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LandingPage from "./components/landingPage";
import Login from "./components/logIn";
import styled from "styled-components";
import DashBoard from "./components/dashboard";
import EventCode from "./components/eventCode";
import ScanPage from "./components/scanPage";
import qrGenerator from "./components/qrGenerator";

//admin
import AdminDashboard from "./components/admin/adminDashboard";
//event
import EventList from "./components/admin/events/eventList";
import Event from "./components/admin/events/event";
import NewEvent from "./components/admin/events/newEvent";

//org
import OrganizerList from "./components/admin/organizer/organizerList";
import Organizer from "./components/admin/organizer/organizer";
import NewOrganizer from "./components/admin/organizer/newOrganizer";

const Header = styled.div`
  width: 100%;
  height: 40px;
  background-color: #3ca1eb;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function App() {
  return (
    <div>
      <Header />
      <Router>
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route path="/login" component={Login} />
          <Route path="/dashboard" component={DashBoard} />
          <Route path="/event-code" component={EventCode} />
          <Route path="/event/:id" component={ScanPage} />
          <Route path="/qr/:whatsappId" component={qrGenerator} />
          {/* Admin  */}
          <Route path="/admin" component={AdminDashboard} />
          <Route path="/admin/events" component={EventList} />
          <Route path="/admin/events/:eventId" component={Event} />
          <Route path="/admin/new-event" component={NewEvent} />
          <Route path="/admin/organizers" component={OrganizerList} />
          <Route path="/admin/organizers/:id" component={Organizer} />
          <Route path="/admin/new-organizer" component={NewOrganizer} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
