import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { fetchUser } from "../actions/index";
import ReactLoading from "react-loading";
import userIcon from "../assets/images/user-icon.png";

const MainCont = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;
const UserIcon = styled.img`
  align-self: center;
  width: 60%;
  height: auto;
`;
const Greeting = styled.h1`
  font-size: 40px;
`;
const Button = styled.div`
  border: 1px solid grey;
  width: 85%;
  height: 55px;
  align-self: center;
  margin-top: 40px;
  display: flex;
  align-items: center;
  background-color: #3ca1eb;
  justify-content: center;
`;

const ButtonText = styled.h2`
  color: white;
`;

const ButtonBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const Dashboard = props => {
  const handleScan = data => {
    if (data) {
      alert(data);
    }
  };
  const handleError = err => {
    console.error(err);
  };
  const handleButton = () => {
    props.history.push("/event-code");
  };
  if (!props.loading) {
    return (
      <MainCont>
        <h1>WeAreApp</h1>
        <UserIcon src={userIcon} />
        <Greeting>{`Hola, ${props.user.name}`}</Greeting>
        <ButtonBox>
          <Button onClick={handleButton}>
            <ButtonText>Registar asistencias</ButtonText>
          </Button>
          <Button>
            <ButtonText> Ver stats</ButtonText>
          </Button>
        </ButtonBox>
      </MainCont>
    );
  } else {
    return (
      <MainCont>
        <ReactLoading type={"spinningBubbles"} color="black" />
      </MainCont>
    );
  }
};

const mapStateToProps = state => {
  return {
    user: state.user,
    loading: state.fetching
  };
};

export default connect(
  mapStateToProps,
  {}
)(Dashboard);
