import { FETCHING, GET_USER, GET_EVENTS, ADDED, ERROR } from "../actions";

const initialState = {
  fetching: false,
  user: null,
  events: null,
  event: [],
  error: null,
  count: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCHING:
      return {
        ...state,
        fetching: true,
        error: null
      };
    case GET_USER:
      return {
        ...state,
        user: action.payload,
        error: null,
        fetching: false
      };
    case ERROR:
      return {
        ...state,
        error: action.payload,
        fetching: false
      };
    case GET_EVENTS:
      return {
        ...state,
        error: null,
        events: action.payload.events,
        fetching: false
      };
    case ADDED:
      return {
        ...state,
        count: state.count + 1,
        fetching: false
      };
    default:
      return state;
  }
};
