import React, { useState } from "react";
// import { Form, Field, withFormik } from "formik";
import styled from "styled-components";
import logo from "../assets/images/logo.png";
import axios from "axios";

import { connect } from "react-redux";
import { fetchUser } from "../actions/index";

import * as Yup from "yup";
const MainCont = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;
const Img = styled.img`
  margin-top: 20px;
  align-self: center;
  width: 60%;
  height: auto;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 50px;
  height: 180px;
  justify-content: space-around;
  margin-bottom: 60px;
`;
const Input = styled.input`
  border-top: 0 solid white;
  border-left: 0 solid white;
  border-right: 0 solid white;
  border-bottom: 1px solid black;
  width: 80%;
  font-size: 30px;
`;
const Button = styled.div`
  border: 1px solid grey;
  width: 70%;
  height: 55px;
  align-self: center;
  margin-top: 40px;
  display: flex;
  align-items: center;
  background-color: #3ca1eb;
  justify-content: center;
`;
const ButtonText = styled.h2`
  color: white;
`;

const Login = props => {
  const [input, setInput] = useState({ numero: "", pass: "" });

  const handleInput = e => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };
  const handleButton = () => {
    props.fetchUser(input.numero);
    props.history.push("/dashboard");
  };
  return (
    <MainCont>
      <h1>Inicia Sesión</h1>
      <Img src={logo} />
      <Form>
        <Input
          placeholder="Numero"
          name="numero"
          onChange={handleInput}
          value={input.numero}
        />
        <Input
          placeholder="Contraseña"
          name="pass"
          onChange={handleInput}
          value={input.pass}
        />
      </Form>
      <Button onClick={handleButton}>
        <ButtonText>Aceder</ButtonText>
      </Button>
    </MainCont>
  );
};

// Higher Order Component - HOC
// Returns a new component (copy of UserForm but with extended logic)

const mapStateToProps = state => {
  return {
    user: state.user,
    loading: state.fetching
  };
};

export default connect(
  () => {},
  { fetchUser }
)(Login);
