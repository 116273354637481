import React from "react";
import userIcon from "../assets/images/logo.png";
import styled from "styled-components";

const CardCont = styled.div`
  width: 90%;
  height: 100px;
  border: 1px solid grey;
  border-radius: 5px;
  display: flex;
  padding: 10px;
  margin-bottom: 15px;
`;

const Img = styled.img`
  width: 25%;
  height: auto;
`;
const RightCont = styled.div`
  width: 70%;
`;

const EventCard = props => {
  console.log(props);
  return (
    <CardCont onClick={() => props.history.push(`/event/${props.event.id}`)}>
      <Img src={userIcon} />
      <RightCont>
        <h2>{props.event.name}</h2>
        <p>{props.event.date}</p>
      </RightCont>
    </CardCont>
  );
};

export default EventCard;
