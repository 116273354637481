import React from "react";
import styled from "styled-components";

const MainCont = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;
const adminDashboard = () => {
  return (
    <MainCont>
      <h1>Admin dashboard</h1>
    </MainCont>
  );
};

export default adminDashboard;
