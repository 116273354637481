import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ReactLoading from "react-loading";
import QRCode from "qrcode.react";

const MainCont = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const QrGenerator = props => {
  const [whatsappId, setWhatsappId] = useState(null);

  useEffect(() => {
    setWhatsappId(props.match.params.whatsappId);
  }, []);

  if (whatsappId) {
    return (
      <MainCont>
        <h1>Tu Codigo</h1>
        <QRCode value={whatsappId} />
      </MainCont>
    );
  } else {
    return (
      <MainCont>
        <ReactLoading type={"spinningBubbles"} color="black" />
      </MainCont>
    );
  }
};

export default QrGenerator;
