import React, { useState } from "react";
import QrReader from "react-qr-reader";
import styled from "styled-components";
import { connect } from "react-redux";
import { attended } from "../actions/index";

const MainCont = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;
const Card = styled.div`
  margin-top: 25px;
  border: 1px solid lightgrey;
  background-color: whitesmoke;
  width: 80%;
  padding: 5%;
  height: 200px;
`;
const H1 = styled.h1`
  text-align: center;
  font-size: 70px;
  color: #3ca1eb;
`;

const ScanPage = props => {
  const [scanned, setScanned] = useState([]);
  console.log("scanned :", scanned);
  const handleScan = data => {
    // console.log("data :", data);
    if (data) {
      if (scanned.includes(data)) {
        alert("already scanned");
        // console.log("alre", scanned);
      } else {
        setScanned([...scanned, data]);
        props.attended(props.match.params.id, data);
      }
      //need to get the numbers per event to check for repeats
    }
  };

  const handleError = err => {
    console.error(err);
  };

  return (
    <MainCont>
      <h1>Escanea el QR</h1>

      <QrReader
        delay={1000}
        onError={handleError}
        onScan={handleScan}
        style={{ width: "80%", margin: "0 auto" }}
      />
      <h2>Noche Astronomica</h2>
      <Card>
        <h3>Personas Registradas:</h3>
        <H1>{props.count}</H1>
      </Card>
    </MainCont>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user,
    count: state.count,
    loading: state.fetching
  };
};

export default connect(
  mapStateToProps,
  { attended }
)(ScanPage);
