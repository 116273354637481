import axios from "axios";

export const FETCHING = "FETCHING";
export const GET_USER = "GET_USER";
export const ERROR = "ERROR";
export const GET_EVENTS = "GET_EVENTS";
export const ADDED = "ADDED";

export const fetchUser = numero => dispatch => {
  dispatch({ type: FETCHING });
  axios
    .get(`https://wearegoing.herokuapp.com/api/user/521${numero}@c.us`)
    .then(res =>
      dispatch({
        type: GET_USER,
        payload: res.data
      })
    )
    .catch(error => {
      dispatch({
        type: ERROR,
        payload: error
      });
    });
};

//get events by org

export const eventsByOrg = id => dispatch => {
  console.log("fire");
  dispatch({ type: FETCHING });
  axios
    .get(`https://wearegoing.herokuapp.com/api/event/user/${id}`)
    .then(response => {
      console.log(response);
      dispatch({ type: GET_EVENTS, payload: response.data });
    })
    .catch(err => {
      console.log(err);
      dispatch({ type: ERROR, payload: err });
    });
};

//add attendee
export const attended = (eventId, userId) => dispatch => {
  console.log(attended);
  dispatch({ type: FETCHING });
  axios
    .post(
      `https://wearegoing.herokuapp.com/api/event/attended/${eventId}/${userId}`
    )
    .then(res => dispatch({ type: ADDED, payload: res.data }))
    .catch(err => dispatch({ type: ERROR, payload: err }));
};
