import React from "react";
import styled from "styled-components";
const MainCont = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const Event = () => {
  return (
    <MainCont>
      <h1>Event</h1>
    </MainCont>
  );
};

export default Event;
