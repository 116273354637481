import React from "react";
import styled from "styled-components";
import { MdMenu } from "react-icons/md";
import logo from "../assets/images/logo.png";
const MainCont = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const Header = styled.div`
  width: 100%;
  height: 40px;
  background-color: #3ca1eb;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Img = styled.img`
  margin-top: 20px;
  align-self: center;
  width: 60%;
  height: auto;
`;

const Button = styled.div`
  border: 1px solid grey;
  width: 70%;
  height: 55px;
  align-self: center;
  margin-top: 40px;
  display: flex;
  align-items: center;
  background-color: #3ca1eb;
  justify-content: center;
`;

const Card = styled.div`
  margin-top: 25px;
  border: 1px solid lightgrey;
  background-color: whitesmoke;
  width: 80%;
  padding: 5%;
`;

const ButtonText = styled.h2`
  color: white;
`;

const LandingPage = props => {
  const handleButton = () => {
    props.history.push("/login");
  };

  return (
    <MainCont>
      {/* 
    header
    we are logo 
    iniciar sesion 
     */}

      <h1>Bienvenido organizador!</h1>
      <Img src={logo} />
      <Card>
        <h3>Instrucciones</h3>
        <p>
          Ad do nostrud est est laboris ex. Dolore pariatur in magna consequat
          aute amet sit quis consequat. Velit ullamco mollit aliquip esse irure
          magna esse minim non sunt. Aute adipisicing deserunt culpa mollit id
          qui laboris velit occaecat sunt veniam ea dolor. Labore exercitation
          velit ut esse commodo ipsum eiusmod consequat ipsum reprehenderit sunt
          occaecat et. Culpa ipsum consectetur do sint elit non quis nisi.
        </p>
      </Card>
      <Button onClick={handleButton}>
        <ButtonText>Entrar</ButtonText>
      </Button>
    </MainCont>
  );
};

export default LandingPage;
