import React, { useEffect } from "react";
import styled from "styled-components";
import logo from "../assets/images/logo.png";
import { connect } from "react-redux";
import { eventsByOrg } from "../actions/index";
import ReactLoading from "react-loading";
import EventCard from "./eventCard";

const MainCont = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  text-align: center;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 20px;
  height: 180px;
  justify-content: space-around;
  margin-bottom: 60px;
`;
const Input = styled.input`
  border-top: 0 solid white;
  border-left: 0 solid white;
  border-right: 0 solid white;
  border-bottom: 2px solid black;
  width: 80%;
  font-size: 30px;
  text-align: center;
`;
const Img = styled.img`
  margin-top: 40px;
  align-self: center;
  width: 60%;
  height: auto;
  margin-bottom: 30px;
`;

const Button = styled.div`
  border: 1px solid grey;
  width: 70%;
  height: 55px;
  align-self: center;
  margin-top: 40px;
  display: flex;
  align-items: center;
  background-color: #3ca1eb;
  justify-content: center;
`;
const ButtonText = styled.h2`
  color: white;
`;
const EventCode = props => {
  console.log(props);
  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = () => props.eventsByOrg(props.user.whatsappId);

  if (props.events) {
    return (
      <MainCont>
        <h1>Tus actividades</h1>
        <Img src={logo} />
        {props.events.map(event => (
          <EventCard event={event} {...props} />
        ))}
      </MainCont>
    );
  } else {
    return (
      <MainCont>
        <ReactLoading type={"spinningBubbles"} color="black" />
      </MainCont>
    );
  }
};

const mapStateToProps = state => {
  return {
    user: state.user,
    events: state.events,
    loading: state.fetching
  };
};

export default connect(
  mapStateToProps,
  { eventsByOrg }
)(EventCode);
